import React from "react";
import MediaQuery from 'react-responsive';

import StarHeadline from "./StarHeadline";
import { Col, Media, Row } from 'reactstrap';
import Master_Im from '../../../tiger_im_web/src/images/master_im_head.jpg';


class Instructor extends React.Component{
    render() {
        return (
            <div>
                <MediaQuery minDeviceWidth={1224}>
                    <Row>
                        <Col lg={'4'}>
                            <Media object style={{width:'100%'}} src={Master_Im} alt={'Master Im photo'}/>
                            <br/>
                            <br/>
                            <StarHeadline headline={'Master Im'} style={{color:'black'}} justify={'left'}/>
                            <br/>
                        </Col>
                        <Col>
                            <div className={'tiger'}>
                                <br/>
                                <br/>
                                <br/>
                                Hello and welcome to Tiger Im's Martial Arts!  With over 30 years of practicing martial
                                arts <br/>and decades of teaching experience, our mission is to help students attain their goal
                                whether it be fitness, self defense or respect, confidence and responsibility.  Our family
                                oriented traditional martial arts philosophy will teach children respect, self discipline,
                                and confidence.  Our adults program focuses on improving and maintaining a healthy body
                                and mind.  Join us and begin your journey to a stronger future now!
                                <br/>
                                <br/>
                                - Master Im
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </Col>
                    </Row>
                </MediaQuery>
                <MediaQuery maxDeviceWidth={1224}>
                    <Row>
                        <Col>
                            <Media object className={'center-block'} src={Master_Im} alt={'Master Im photo'}/>
                            <br/>
                            <StarHeadline headline={'Master Im'} style={{color:'black'}} justify={'left'}/>
                            <br/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <div className={'tiger'}>
                                <br/>
                                <br/>
                                <br/>
                                Hello and welcome to Tiger Im's Martial Arts!  With over 30 years of practicing martial
                                arts <br/>and decades of teaching experience, our mission is to help students attain their goal
                                whether it be fitness, self defense or respect, confidence and responsibility.  Our family
                                oriented traditional martial arts philosophy will teach children respect, self discipline,
                                and confidence.  Our adults program focuses on improving and maintaining a healthy body
                                and mind.  Join us and begin your journey to a stronger future now!
                                <br/>
                                <br/>
                                - Master Im
                                <br/>
                                <br/>
                                <br/>
                            </div>
                        </Col>
                    </Row>
                </MediaQuery>
            </div>
        );
    }
}

export default Instructor;