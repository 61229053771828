import React from "react";
import MediaQuery from 'react-responsive';
import FontAwesome from 'react-fontawesome'
import {Col, Row} from 'reactstrap';

class Contact extends React.Component{
    render() {
        let text_1_Style = {
            display: 'flex', justifyContent: 'center', fontcolor:'#000000', fontFamily: 'Impact',fontSize:'28px'
        }
        let text_2_Style = {
            display: 'flex', justifyContent: 'center',  fontcolor:'#000000', fontFamily: 'Impact',fontSize:'22px',
        }
        let bucketStyle = {
            marginTop: '20px',
            //padding: '4em',
            margin: '0 auto',
            textAlign: 'center'
        }
        return (
            <div>
                <MediaQuery minDeviceWidth={1224}>
                    <Row className={'tiger_3'}>
                        <Col lg={'4'} style={bucketStyle}>
                            <br/>
                            <FontAwesome
                                name='users'
                                size='5x'
                                style={{ color:'#fcd75b', }}
                            />
                            <div style={text_1_Style}>Stop by the Dojang</div>
                            <div style={text_2_Style}>
                                1075 Garrisonville Road, <br/>
                                Stafford, Virginia 22556
                            </div>
                            <span >MON-FRI 5:30am-9:00pm<br/>SAT 9:00am-12:00pm</span>
                            <br/>
                        </Col>
                        <Col lg={'4'} style={bucketStyle}>
                            <br/>
                            <FontAwesome
                                name='mobile'
                                size='5x'
                                style={{ color:'#fcd75b', }}
                            />
                            <div style={text_1_Style}>Give Us a Call</div>
                            <div style={text_2_Style}>540-628-2444</div>
                            <br/>
                        </Col>
                        <Col lg={'4'} style={bucketStyle}>
                            <br/>
                            <FontAwesome
                                name='envelope-open'
                                size='5x'
                                style={{ color:'#fcd75b',}}
                            />
                            <div style={text_1_Style}>Send Us a Message</div>
                            <div style={text_2_Style}>MasterIm@TigerImsMA.com</div>
                            <br/>
                        </Col>
                    </Row>
                    <Row style={{fontSize:'3em', color:'#000000', opacity:'.45', textAlign: 'center'}}>
                        <Col lg={'2'} ></Col>
                        <Col lg={'2'} >
                            <a target="_blank" href={"https://www.facebook.com/Tiger-Ims-Marital-Arts-1892478044364053/"}><i className="fab fa-facebook-square social-link" ></i></a>
                        </Col>
                        <Col lg={'2'} >
                            <a target="_blank" href={"https://www.instagram.com/tiger_ims_stafford/?hl=en"}><i className="fab fa-instagram social-link" ></i></a>
                        </Col>
                        <Col lg={'2'} >
                            <a target="_blank" href={"https://itunes.apple.com/us/app/tiger-im/id1348473520?ls=1&mt=8"}><i className="fab fa-app-store-ios social-link"></i></a>
                        </Col>
                        <Col lg={'2'} >
                            <a target="_blank" href={"https://play.google.com/store/apps/details?id=com.tigerim.TigerIm"}><i className="fab fa-android social-link"></i></a>
                        </Col>
                        <Col lg={'2'} ></Col>
                    </Row>

                </MediaQuery>
                <MediaQuery maxDeviceWidth={1224}>
                    <Row className={'tiger_3'}>
                        <Col style={bucketStyle}>
                            <FontAwesome
                                name='users'
                                size='5x'
                                style={{ color:'#fcd75b', }}
                            />
                            <div style={text_1_Style}>Stop by the Dojang</div>
                            <div style={text_2_Style}>
                                1075 Garrisonville Road, <br/>
                                Stafford, Virginia 22556
                            </div>
                            <span >MON-FRI 5:30am-9:00pm<br/>SAT 9:00am-12:00pm</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={bucketStyle}>
                            <FontAwesome
                                name='mobile'
                                size='5x'
                                style={{ color:'#fcd75b', }}
                            />
                            <div style={text_1_Style}>Give Us a Call</div>
                            <div style={text_2_Style}>540-628-2444</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col style={bucketStyle}>
                            <FontAwesome
                                name='envelope-open'
                                size='5x'
                                style={{ color:'#fcd75b',}}
                            />
                            <div style={text_1_Style}>Send Us a Message</div>
                            <div style={text_2_Style}>MasterIm@TigerImMartialArts.com</div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={'3'}>
                            <a target="_blank" href={"https://www.facebook.com/Tiger-Ims-Marital-Arts-1892478044364053/"} ><i className="fab fa-facebook-square fa-3x social-link" ></i></a>
                        </Col>
                        <Col md={'3'}>
                            <a target="_blank" href={"https://www.instagram.com/tiger_ims_stafford/?hl=en"}><i className="fab fa-instagram social-link fa-3x" ></i></a>
                        </Col>
                        <Col md={'3'}>
                            <a target="_blank" href={"https://itunes.apple.com/us/app/tiger-im/id1348473520?ls=1&mt=8"}><i className="fab fa-app-store-ios social-link fa-3x"></i></a>
                        </Col>
                        <Col md={'3'}>
                            <a target="_blank" href={"https://play.google.com/store/apps/details?id=com.tigerim.TigerIm"}><i className="fab fa-android social-link fa-3x"></i></a>
                        </Col>
                    </Row>
                </MediaQuery>
            </div>

        );
    }
}

export default Contact;
