import React from "react";





class Footer extends React.Component{
    render() {
        let textStyle = {
            display: 'flex', justifyContent: 'center', marginLeft:'1.5em', fontcolor:'#000000',
        }
        return (
            <div id="footer" className="footer" style={{ justifyContent: 'center'}}>
                <div style={textStyle}>
                    <p>&copy; 2018 TigerImMartialArts.com</p>
                </div>

            </div>
        );
    }
}

export default Footer;