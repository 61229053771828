import React from "react";
import {Carousel, CarouselItem} from "react-bootstrap";
import {Media} from 'reactstrap';
import Quote1 from '../../../tiger_im_web/src/images/quote1.jpg';
import Quote2 from '../../../tiger_im_web/src/images/quote2.jpg';
import MasterIm1 from '../../../tiger_im_web/src/images/master_im_1.jpg'
import MasterIm2 from '../../../tiger_im_web/src/images/master_im_2.jpg'
import MasterIm3 from '../../../tiger_im_web/src/images/master_im_3.jpg'
import MasterIm4 from '../../../tiger_im_web/src/images/master_im_4.jpg'
// import Clip5 from '../images/clip5.jpg';
// import Clip6 from '../images/clip6.jpg';
// import Clip7 from '../images/clip7.jpg'

class Gallery extends React.Component{
    render() {
        return (
            <Carousel  indicators={false} controls={true}>
                <Carousel.Item className={"carousel-item"}>
                    <Media object style={{width:'100%'}} src={MasterIm3} alt={'clip 5'}/>
                </Carousel.Item>
                <CarouselItem className={"carousel-item"}>
                    <Media object style={{width:'100%'}} src={Quote1} alt={'Marcy N. quote'}/>
                </CarouselItem>
                <Carousel.Item className={"carousel-item"}>
                    <Media object style={{width:'100%'}} src={MasterIm1} alt={'clip 5'}/>
                </Carousel.Item>
                <Carousel.Item className={"carousel-item"}>
                    <Media object style={{width:'100%'}} src={Quote2} alt={'Matt S. quote'}/>
                </Carousel.Item>
                <Carousel.Item className={"carousel-item"}>
                    <Media object style={{width:'100%'}} src={MasterIm2} alt={'clip 7'}/>
                </Carousel.Item>
                <Carousel.Item className={"carousel-item"}>
                    <Media object style={{width:'100%'}} src={MasterIm4} alt={'clip 5'}/>
                </Carousel.Item>
            </Carousel>
        );
    }
}

export default Gallery;