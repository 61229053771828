import React, { Component } from 'react';
import {Container, Col, Row} from 'reactstrap'

import Welcome from "./components/Welcome";
import Instructor from "./components/Instructor";
import Contact from "./components/Contact";
import Map from './components/Map'
import Footer from "./components/Footer"
import Gallery from "./components/Gallery";
import Header from "./components/Header";

import Motto from "./components/Motto";
import ResponsiveParallax from "./components/ResponsiveParallax";

import TKD2 from '../../tiger_im_web/src/images/tkd_2.jpg'
import TKD3 from '../../tiger_im_web/src/images/tkd_3.jpg'

import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import './index.css';


class App extends Component {

    render() {
        return (
            <Container >
                <Row>
                    <Col style={{width:'100%'}}>
                        <Header/>
                    </Col>
                </Row>
                <Row>
                    <Col style={{width:'100%'}}>
                        <Motto/>
                    </Col>
                </Row>
                <Row>
                    <Col style={{width:'100%'}}>
                        <div className={'content_header'}></div>
                    </Col>
                </Row>
                <Welcome/>
                <br/>
                <br/>
                <Row>
                    <Col style={{width:'100%'}}>
                        <div className={'content_footer'}></div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{width:'100%'}}>
                        <ResponsiveParallax id={'tkd_2'}
                                            img={'/images/tkd_2.jpg'}
                                            className={''}
                                            media={TKD2}
                                            alt={'Taekwondo fist'}/>
                    </Col>
                </Row>
                <Row>
                    <Col style={{width:'100%'}}>
                        <div className={'content_header'}></div>
                    </Col>
                </Row>
                <Instructor/>
                <Row>
                    <Col style={{width:'100%'}}>
                        <div className={'content_footer'}></div>
                    </Col>
                </Row>
                <Row>
                    <Col style={{width:'100%'}}>
                        <ResponsiveParallax id={'tkd_3'}
                                            img={'/images/tkd_3.jpg'}
                                            className={''}
                                            media={TKD3}
                                            alt={'Taekwondo high kick'}/>
                    </Col>
                </Row>
                <Row>
                    <Col >
                        <div className={'content_header'}></div>
                    </Col>
                </Row>
                <Row  className={'gallery-row'}>
                    <Col id={'gallery'} className={'gallery'}>
                        <Gallery/>
                    </Col>
                </Row>
                <Contact/>
                <Row>
                    <Col style={{width:'100%'}}>
                        <Map />
                    </Col>
                </Row>
                <Row>
                    <Col style={{width:'100%'}}>
                        <Footer />
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default App;
