import React from "react";

class LoremIpsum extends React.Component{

    render() {
        return (
            <div className={this.props.class}>
                <p>Lorem ipsum dolor sit amet, ne nihil munere scripserit cum, te cum prima quando consul, vel feugiat officiis periculis in. Enim tamquam volutpat cum no, malis praesent et per. Idque dignissim omittantur has ex, inani reformidans cum ex. Pro enim mnesarchum ea, zril nostrum ex vis. Admodum dignissim ex his, in verear viderer dissentias has. Vim bonorum nonumes no, cum ipsum invidunt ne, ea nostrud accusam mei.
                </p>
                <p>
                    Sit sapientem erroribus temporibus in, vim et dictas iudicabit, sumo idque adipiscing eum ne. Cum suas dolorem hendrerit ei, veniam quidam impedit ius ne, ubique animal dolorum pro ad. Assum scriptorem te mel. Quodsi ancillae voluptua no sea, qui omnes convenire ex, ut mea aeque libris sanctus. Vis ad omnis dicant, per liber verear eligendi eu, cum at tamquam postulant.
                </p>
                <p>
                    Graeci mentitum suscipiantur eam id, congue dolores vituperata ex pro. Graecis persecuti has ne, simul nobis instructior ei vix. Vero copiosae intellegebat eos in. Sea ut primis scripta accusamus.
                </p>
                <p>
                    Duo ei harum nonumes tractatos. Cu dolorum repudiare pri, et eum admodum dissentiunt. Vel everti doctus maiestatis id, qui ut tollit bonorum. Minim molestiae adversarium ad qui, sale quas vix ei.
                </p>
                <p>
                    An eius dicant sed, pri ei commodo appellantur comprehensam, id impetus ocurreret nam. Vocent alterum sed ei, vel ea nobis essent electram, ut mea wisi doctus. Pri fastidii antiopam ei, in vis consul omnesque, primis vivendum mel eu. Ex nulla probatus insolens qui, te ius assum intellegat, nec ad scriptorem consectetuer. Ceteros maluisset has at, at mea quis erroribus consequuntur. Sed eu causae quaerendum scribentur.
                </p>
            </div>
        );
    }
}

export default LoremIpsum;