import React from "react";
import { Col, Media, Row } from 'reactstrap';
import MediaQuery from 'react-responsive';

import LoremIpsum from './LoremIpsum';
import Trial from "./Trial";
import Kukiwon from '../../../tiger_im_web/src/images/kukkiwon_logo.jpg'
import WTF from '../../../tiger_im_web/src/images/wtf_logo.jpg'
import star from '../../../tiger_im_web/src/images/star_icon.png';



class Welcome extends React.Component{

     render() {
         let text_2_Style = {
             display: 'flex',
             justifyContent: 'center',
             fontcolor:'#000000',
             fontFamily: 'Impact',
             fontSize:'48px',
         }
         return (
            <div>
                <MediaQuery minDeviceWidth={1224}>
                    <Row>

                        <Col className={'tiger_1'}>
                            <div style={text_2_Style}>Welcome to Tiger Im's Martial Arts</div>
                            <div>
                                We are a family centered martial arts studio located in Stafford, VA dedicated to providing a
                                safe and clean training experience for students of all ages.  Our
                                curriculum focuses on promoting self confidence, respect, discipline and
                                improved physical fitness all while having fun.
                                <br/>
                                <br/>
                                We offer:
                                <ul>
                                    <li >Kids/Teens/Adults Tae kwon do training</li>
                                    <li >Before and After School care</li>
                                    <li >Summer Camps</li>
                                    <li >Birthday Parties</li>
                                </ul>
                                <br/>
                                Contact us or visit the studio to see how we can help you
                            </div>
                        </Col>
                    </Row>

                </MediaQuery>
                <MediaQuery maxDeviceWidth={1224}>

                     <Row>
                         <Col >
                             <div style={text_2_Style}>Welcome to Tiger Im's Martial Arts</div>
                             <div>
                                 We are a family centered martial arts studio located in Stafford, VA dedicated to providing a
                                 safe and clean training experience for students of all ages.  Our
                                 curriculum focuses on promoting self confidence, respect, discipline and
                                 improved physical fitness all while having fun.
                                 <br/>
                                 <br/>
                                 We offer:
                                 <ul>
                                     <li >Kids/Teens/Adults Tae kwon do training</li>
                                     <li >Before and After School care</li>
                                     <li >Summer Camps</li>
                                     <li >Birthday Parties</li>
                                 </ul>
                                 <br/>
                                 Contact us or visit the studio to see how we can help you
                             </div>
                         </Col>
                     </Row>
                 </MediaQuery>
            </div>
        );
    }
}

export default Welcome;
