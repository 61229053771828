import React from "react";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import {Button, Col, Row } from 'reactstrap'

class Trial extends React.Component{
    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleInvalidSubmit = this.handleInvalidSubmit.bind(this);
        this.state={ name:"", email:"", phone:"" }
    }

    handleSubmit(e) {
        e.preventDefault();
        let data = {
            "name": this.state.name,
            "email": this.state.email,
            "phone": this.state.phone,}

        fetch('http://localhost:8081/email', {
            method: 'post',
            headers: {'Content-Type':'application/json'},
            body: JSON.stringify(data)
        });
    }
    handleInvalidSubmit(event, errors, values) {
        this.setState({email: values.email, error: true});
    }
    render() {
        let weekStyle = {
            color:'#fcd75b',
            fontFamily: 'Impact',
            fontSize: '54px',
            textAlign: 'center',
            letterSpacing: '5px',
            marginBottom: '0px !important',
            lineHeight:'1em'
        }
        let trialStyle ={
            marginTop: '20px',
            borderRadius: '10px',
            border: '1px solid #000000'
        }
        let controlStyle = {
            margin: '1.0em 1.5em 0 1.5em'
        }
        let submitStyle = {
            marginTop:'1.0em',
            marginLeft:'auto',
            marginRight:'auto',
            color:'#fcd75b',
        }
        return (
            <div style={trialStyle}>
                <AvForm onValidSubmit={this.handleSubmit} onInvalidSubmit={this.handleInvalidSubmit}>
                <Row >
                    <Col style={weekStyle}>
                        FREE TRIAL
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AvField name="name" placeholder={"* Full Name"} required  errorMessage="Please enter your full name!"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AvField name="email" placeholder={"* Email"} type="email" required errorMessage="Please enter a valid email!"/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <AvField name="phone" placeholder={"* Phone xxx-xxx-xxxx"} type="phone" required errorMessage="Please enter a valid phone number!"/>
                    </Col>
                </Row>
                <Row>
                    <Col style={{textAlign:'center'}}>
                        <Button color={'link'} style={submitStyle} onClick={this.handleSubmit}>Register</Button>
                        <br/>
                        <br/>
                    </Col>
                </Row>
                </AvForm>
            </div>
        );
    }
}

export default Trial;

{/*<div style={trialStyle} >*/}
    {/*<div style={weekStyle}>*/}
        {/*FREE TRIAL*/}
    {/*</div>*/}
    {/*<div className={'row'}>*/}
        {/*<FormControl*/}
            {/*type="text"*/}
            {/*value={this.state.name}*/}
            {/*placeholder="*Full Name"*/}
            {/*style={controlStyle}*/}
            {/*onChange={(ev)=>this.setState({name:ev.target.value})}*/}
        {/*/>*/}
    {/*</div>*/}
    {/*<div className={'row'}>*/}
        {/*<FormControl*/}
            {/*type="text"*/}
            {/*value={this.state.email}*/}
            {/*placeholder="*Email"*/}
            {/*style={controlStyle}*/}
            {/*onChange={(ev)=>this.setState({email:ev.target.value})}*/}
        {/*/>*/}
    {/*</div>*/}
    {/*<div className={'row'}>*/}
        {/*<FormControl*/}
            {/*type="text"*/}
            {/*value={this.state.phone}*/}
            {/*placeholder="*Phone"*/}
            {/*style={controlStyle}*/}
            {/*onChange={(ev)=>this.setState({phone:ev.target.value})}*/}
        {/*/>*/}
    {/*</div>*/}
    {/*<div className={'row'}>*/}
        {/*<Button style={submitStyle} onClick={this.handleSubmit}>Register</Button>*/}
    {/*</div>*/}
    {/*<br/>*/}
{/*</div>*/}