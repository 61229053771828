import React from "react";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker
} from "react-google-maps";

const LATITUDE = 38.4740657;
const LONGITUDE = -77.4694400;

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
    <GoogleMap
        defaultZoom={13}
        defaultCenter={{ lat: LATITUDE, lng: LONGITUDE }}
    >
        <Marker position={{ lat: LATITUDE, lng: LONGITUDE }}/>
    </GoogleMap>
))


class Map extends React.Component{
    render() {
        return (
            <div>
                <div className={'content_footer'}></div>
                <MyMapComponent
                    isMarkerShown
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyAFFB1-_iLLfGC5OONbBQknbyixNhCAsrQ&callback=initMap"
                    loadingElement={<div style={{ height: `100%` }} />}
                    containerElement={<div style={{ height: `400px` }} />}
                    mapElement={<div style={{ height: `100%` }} />}
                />
            </div>
        );
    }
}

export default Map;
