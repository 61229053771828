import React from "react";

class StarHeadline extends React.Component{
    render() {
        return (
            <div >
                <div className={'row'} style={{display: 'flex', justifyContent: 'center'}}>
                    <div > <div className={'hl_line'} /></div>
                    <div > <img  className={'hl_star'} src="/images/star_icon.png" alt="star" /></div>
                    <div > <div className={'hl_line'} /></div>
                </div>
                <div className={'row'} style={{display: 'flex', justifyContent: 'center'}}>
                    <div className={'hl_headline'} style={this.props.style}>{this.props.headline}</div>
                </div>
                <div className={'row'} style={{display: 'flex', justifyContent: 'center'}}>
                    <div > <div className={'hl_line'} /></div>
                    <div > <img  className={'hl_star'} src="/images/star_icon.png" alt="star" /></div>
                    <div > <div className={'hl_line'} /></div>
                </div>
            </div>
        );
    }
}

export default StarHeadline;
