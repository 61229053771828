import React from 'react'

class Lettering extends React.Component {
    getSpanElement(elem, className, index, spacer) {
        const key = `${className}${index}`
        return <span key={key} className={`${className} ${key}`}>{elem}{spacer}</span>
    }
    getWrappedChars() {
        let lineIndex = 0, wordIndex = 0, charIndex = 0
        const { children, lineClass, wordClass, charClass } = this.props,
            childrenArr = children.split('\\n')
        const lines = childrenArr.map(line => {
            lineIndex += 1
            const wordsArray = line.split(' ')
            const words = wordsArray.map(word => {
                wordIndex += 1
                const charsArray = word.split('')
                const chars = charsArray.map(char => {
                    charIndex += 1
                    return this.getSpanElement(char, charClass, charIndex)
                })
                return this.getSpanElement(chars, wordClass, wordIndex, ' ')
            })
            return this.getSpanElement(words, lineClass, lineIndex, ' ')
        })
        return lines
    }
    render() {
        const wrappedChars = this.getWrappedChars()
        return React.createElement('h2', {}, wrappedChars);
    }
}
Lettering.defaultProps = {
    className: 'lettering',
    charClass: 'char',
    lineClass: 'line',
    tagName: 'h1',
    wordClass: 'word'
}

export default Lettering;