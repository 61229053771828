import React from "react";
import FontAwesome from 'react-fontawesome';
import Banner from '../../../tiger_im_web/src/images/banner.jpg';
import ResponsiveParallax from "./ResponsiveParallax";

class Header extends React.Component{

    render() {
        let scollStyle = {
            color:'#000000',
            opacity: '.35',
            letterSpacing: '5px',
            textAlign: 'center',
        }
        return (
            <ResponsiveParallax id={'banner'}
                                img={'/images/banner.jpg'}
                                className={'banner'}
                                media={Banner}
                                alt={'Tiger Im Logo'}>
                <br/>
                <div style={scollStyle} >SCROLL<br/>
                <FontAwesome
                name='angle-down'
                size={'2x'}
                style={scollStyle}
                /></div>
            </ResponsiveParallax>
        );
    }
}

export default Header;