import React from "react";
import MediaQuery from 'react-responsive';
import { Parallax } from 'react-parallax';
import { Media } from 'reactstrap';
import Image from '../../../tiger_im_web/src/images/tkd_1.jpg';

import Lettering from "./Lettering";
import $ from "jquery";

class Motto extends React.Component{
    componentDidMount() {
        $("#os-phrases > h2")
            .css('opacity', 1);
    }
    render() {
        return (
            <div>
                <MediaQuery minDeviceWidth={1224}>
                    <Parallax bgImage={'../images/tkd_1.jpg'}
                              strength={500}

                    >
                        <div style={{height: 450}}>
                            <div className="os-phrases" id="os-phrases">
                                <Lettering className="os-phrases" id="os-phrases" tagName="h1" charClass="char">
                                    calm
                                </Lettering>
                                <Lettering className="os-phrases" id="os-phrases" tagName="h1" charClass="char">
                                    but ALERT
                                </Lettering>
                                <Lettering className="os-phrases" id="os-phrases" tagName="h1" charClass="char">
                                    relaxed
                                </Lettering>
                                <Lettering className="os-phrases" id="os-phrases" tagName="h1" charClass="char">
                                    but READY
                                </Lettering>
                                <Lettering className="os-phrases" id="os-phrases" tagName="h1" charClass="char">
                                    smooth
                                </Lettering>
                                <Lettering className="os-phrases" id="os-phrases" tagName="h1" charClass="char">
                                    but SHARP
                                </Lettering>
                                <Lettering className="os-phrases" id="os-phrases" tagName="h1" charClass="char">
                                    humble
                                </Lettering>
                                <Lettering className="os-phrases" id="os-phrases" tagName="h1" charClass="char">
                                    but CONFIDENT
                                </Lettering>
                                <div className="os-phrases" id="os-phrase" >
                                    <h2>
                                        calm but ALERT <br/>
                                        relaxed but READY <br/>
                                        smooth but SHARP <br/>
                                        humble but CONFIDENT
                                    </h2>
                                </div>

                            </div>
                        </div>
                    </Parallax>
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                    <Media object style={{width:'100%'}}src={Image} alt="TKD kick" />
                </MediaQuery>
            </div>
        );
    }
}

export default Motto;