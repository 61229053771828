import React from "react";
import MediaQuery from 'react-responsive';
import { Parallax } from 'react-parallax';
import { Media } from 'reactstrap';

class ResponsiveParallax extends React.Component{

    render() {

        return (
            <div id={this.props.id}>
                <MediaQuery minDeviceWidth={1224}>
                    <Parallax bgImage={this.props.img}
                              strength={500}
                                style={{width:'100%'}}
                              className={this.props.className}>
                        <div style={{height: 450}}>

                        </div>
                        {this.props.children}
                    </Parallax>
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                    <div >
                        <Media object style={{width:'100%'}} src={this.props.media} alt={this.props.alt}/>
                        {this.props.children}
                    </div>
                </MediaQuery>
            </div>
        );
    }
}

export default ResponsiveParallax;